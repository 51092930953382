<ng-template
  #stepTemplate
  let-step="cdkStep"
  let-index="index"
  let-last="last"
  let-selectedIndex="selectedIndex"
>
  <!--Completed-->
  @if (selectedIndex > index) {
    <button class="group flex w-full items-center" (click)="step.select()">
      <span class="flex items-center space-x-4 px-6 py-4 text-sm font-medium">
        <span
          class="bg-primary-600 group-hover:bg-primary-800 flex size-10 shrink-0 items-center justify-center rounded-full transition"
        >
          <wma-icon class="size-6 text-white" name="check" />
        </span>
        <span class="text-sm font-medium text-gray-900">{{ step.label }}</span>
      </span>
    </button>
  }

  <!--Current Step-->
  @if (selectedIndex === index) {
    <div class="flex items-center space-x-4 px-6 py-4 text-sm font-medium">
      <div
        class="border-primary-600 flex size-10 shrink-0 items-center justify-center rounded-full border-2"
      >
        <p class="text-primary-600">{{ index + 1 }}</p>
      </div>
      <p class="text-primary-600 text-sm font-medium">{{ step.label }}</p>
    </div>
  }

  <!--Disabled-->
  @if (selectedIndex < index) {
    <button class="group flex w-full items-center" (click)="selectStep(step)">
      <span class="flex items-center space-x-4 px-6 py-4 text-sm font-medium">
        <span
          class="flex size-10 shrink-0 items-center justify-center rounded-full border-2 border-gray-300 transition group-hover:border-gray-400"
        >
          <span class="text-gray-500 transition group-hover:text-gray-900">{{ index + 1 }}</span>
        </span>
        <span class="text-sm font-medium text-gray-500 transition group-hover:text-gray-900">
          {{ step.label }}
        </span>
      </span>
    </button>
  }

  @if (!last) {
    <div class="absolute right-0 top-0 hidden h-full w-5 md:block">
      <svg
        class="h-full w-full text-gray-300"
        viewBox="0 0 22 80"
        fill="none"
        preserveAspectRatio="none"
      >
        <path
          d="M0 -2L20 40L0 82"
          vector-effect="non-scaling-stroke"
          stroke="currentColor"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  }
</ng-template>

<nav>
  <ul class="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
    @for (step of steps; track step) {
      <li class="relative md:flex md:flex-1">
        <ng-container
          *ngTemplateOutlet="
            stepTemplate;
            context: {
              cdkStep: step,
              index: $index,
              last: $index === steps.length - 1,
              selectedIndex: selectedIndex,
            }
          "
        />
      </li>
    }
  </ul>
</nav>

<div class="mt-4">
  <ng-container [ngTemplateOutlet]="selected ? selected.content : null" />
</div>
<ng-content select="[wma-stepper-actions]" />
