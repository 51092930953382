import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'wma-drawer-container',
  templateUrl: './drawer-container.html',
  host: {
    class: 'flex overflow-hidden',
  },
})
export class DrawerContainerComponent {
  open = false;

  openSidebar(): void {
    this.open = true;
  }

  closeSidebar(): void {
    this.open = false;
  }
}

@Component({
  standalone: true,
  selector: 'wma-drawer-content',
  templateUrl: './drawer-content.html',
  host: {
    class: 'flex-1 relative z-0 overflow-y-auto focus:outline-hidden',
    tabindex: '0',
  },
})
export class DrawerContentComponent {}

@Component({
  standalone: true,
  selector: 'wma-drawer-sidenav',
  templateUrl: './drawer-sidenav.html',
  host: {
    class: 'flex flex-col overflow-y-auto',
  },
})
export class DrawerSidenavComponent {}
